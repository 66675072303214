<template>
    <div class="card">
        <el-form ref="clientForm" class="grid grid-cols-2 gap-8 px-16 py-12" label-position="top" :model="clientData" :rules="clientDataRules">
            <div>
                <el-form-item :label="$t('clients.type')">
                    <el-select v-model="clientData.type" class="w-full" @change="clientTypeChanged">
                        <el-option value="private" :label="$t('clients.private')" />
                        <el-option value="company" :label="$t('clients.company')" />
                    </el-select>
                </el-form-item>
                <el-form-item label="Email" prop="email">
                    <el-input v-model="clientData.email" @change="updateDate" />
                </el-form-item>
                <el-form-item :label="clientData.type === 'company' ? 'Orgnummer' : 'Personummer'">
                    <el-input v-model="clientData.per_org_nummer" @change="updateDate" />
                </el-form-item>
                <el-form-item :label="$t('clients.name')" prop="name">
                    <el-input v-model="clientData.name" @change="updateDate" />
                </el-form-item>
                <el-form-item :label="$t('clients.mobile_app_login_email_is_a_good_choice')" prop="login">
                    <el-input v-model="clientData.login" autocomplete="new-password" @change="updateDate" />
                </el-form-item>
            </div>
            <div>
                <el-form-item :label="$t('clients.custom_id')">
                    <el-input v-model="clientData.custom_id" :disabled="autoCustomId" @change="updateDate" />
                </el-form-item>

                <el-form-item :label="$t('clients.telephone_number')">
                    <el-input v-model="clientData.telephone_number" @change="updateDate" />
                </el-form-item>

                <el-form-item :label="$t('clients.assign_to_manager')">
                    <el-select v-model="clientData.manager_id" clearable class="w-full" @change="updateDate">
                        <el-option v-for="manager in managers" :key="manager.id" :value="manager.id" :label="manager.name + ' ' + manager.surname" />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('clients.surname')" prop="surname">
                    <el-input v-model="clientData.surname" :disabled="clientData.type === 'company'" @change="updateDate" />
                </el-form-item>

                <el-form-item :label="$t('clients.password')" prop="password">
                    <el-input v-model="clientData.password" type="password" :show-password="true" autocomplete="new-password" @change="updateDate" />
                </el-form-item>
            </div>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';

export default {
    data() {
        return {
            managers:   [],
            clientData: {
                type:             'private',
                custom_id:        null,
                name:             null,
                surname:          null,
                login:            null,
                email:            null,
                telephone_number: null,
                password:         null,
                per_org_nummer:   null,
                manager_id:       null,
            },
            clientDataRules: {
                name:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                surname: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                email:   [{ type: 'email', message: this.$t('common.wrong_email_format'), trigger: 'blur' }],
                login:   [{ min: 5, message: this.$t('common.min_5_characters'), trigger: 'blur' }],
            },
        };
    },

    computed: {
        login() {
            return this.clientData.login;
        },
        password() {
            return this.clientData.password;
        },
        autoCustomId() {
            return !!this.$store.state.user.user.auto_client_custom_id;
        },
        clientType() {
            return this.clientData.type;
        },
        clientEmail() {
            return this.clientData.email;
        },
    },

    watch: {
        login() {
            this.changeValidationRules();
        },
        password() {
            this.changeValidationRules();
        },
        clientType() {
            this.changeValidationRules();
        },
        clientEmail() {
            this.clientData.login = this.clientData.email;
            // this.changeValidationRules();
        },
    },

    created() {
        this.getManagers();
    },

    methods: {
        async getManagers() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/managers`);
            this.managers = data;
        },

        clientTypeChanged() {
            if (this.clientData.type === 'company') {
                this.clientData.surname = null;
            }
            this.updateDate();
        },

        changeValidationRules() {
            const ifLoginRequired = !!this.login || !!this.password;
            const ifSurnameRequired = this.clientType === 'private';

            Vue.set(this.clientDataRules, 'login',
                [
                    { required: ifLoginRequired, message: this.$t('common.required'), trigger: 'blur' },
                    { min: 5, message: this.$t('common.min_5_characters'), trigger: 'blur' },
                ]);
            Vue.set(this.clientDataRules, 'password',
                [
                    { required: ifLoginRequired, message: this.$t('common.required'), trigger: 'blur' },
                ]);
            Vue.set(this.clientDataRules, 'surname',
                [
                    { required: ifSurnameRequired, message: this.$t('common.required'), trigger: 'blur' },
                ]);

            this.$refs.clientForm.validate(() => {});
        },

        updateDate() {
            this.$emit('change', this.clientData);
        },
    },
};
</script>
