<template>
    <div class="card">
        <el-form ref="addressForm" class=" px-16 py-8" label-position="top" :model="addressData" :rules="addressDataRules">
            <el-row>
                <el-col :span="24">
                    <el-form-item :label="$t('common.google_map_search')">
                        <AutoComplete @placeFound="placeFound" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="CO">
                        <!-- @Filip -->
                        <el-input v-model="addressData.co" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('clients.street')" prop="street">
                        <!-- @Filip -->
                        <el-input v-model="addressData.street" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item :label="$t('clients.city')" prop="city">
                        <!-- @Filip -->
                        <el-input v-model="addressData.city" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('clients.post_code')" prop="post_code">
                        <!-- @Filip -->
                        <el-input v-model="addressData.post_code" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item :label="$t('clients.home_office_telephone')">
                        <!-- @Filip -->
                        <el-input v-model="addressData.telephone" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('clients.door_code')">
                        <!-- @Filip -->
                        <el-input v-model="addressData.door_code" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item :label="$t('clients.apartment_type')">
                        <!-- @Filip -->
                        <el-select v-model="addressData.apartment_type">
                            <el-option value="house" :label="$t('clients.house')" />
                            <el-option value="flat" :label="$t('clients.flat')" />
                            <el-option value="two_level_flat" :label="$t('clients.two_level_flat')" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('clients.square_meters')">
                        <!-- @Filip -->
                        <el-input v-model="addressData.square_meters" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item :label="$t('clients.alarm_unblock_code')">
                        <!-- @Filip -->
                        <el-input v-model="addressData.unblock_alarm_code" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('clients.alarm_block_code')">
                        <!-- @Filip -->
                        <el-input v-model="addressData.block_alarm_code" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="24">
                    <el-form-item :label="$t('clients.alarm_instructions')">
                        <!-- @Filip -->
                        <el-input v-model="addressData.alarm_code_instructions" type="textarea" resize="none" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="24">
                    <el-form-item :label="$t('clients.check_in_out_radius') + addressData.geofence_radius + 'm'">
                        <!-- @Filip -->
                        <el-slider v-model="addressData.geofence_radius" :min="50" :max="500" :step="10" />
                    </el-form-item>
                </el-col>
            </el-row>

            <GoogleMap
                :coords="{
                    map_lat: addressData.map_lat,
                    map_lng: addressData.map_lng,
                }"
                :geofenceRadius="addressData.geofence_radius"
                :markerDraggable="true"
                @markerDragged="markerMapDragged"
            />
        </el-form>
    </div>
</template>
<script>
export default {
    components: {
        AutoComplete: () => import(/* webpackChunkName: "GoogleAddressAutoComplete" */ '@/components/GoogleMapSearch/AutoComplete.vue'),
        GoogleMap:    () => import(/* webpackChunkName: "GoogleMapForAddress" */ '@/components/GoogleMapForAddress/GoogleMapForAddress.vue'),
    },

    props: {
        addressData: {
            type: Object,
            default() {
                return {
                    co:                      null,
                    post_code:               null,
                    city:                    null,
                    street:                  null,
                    telephone:               null,
                    door_code:               null,
                    apartment_type:          null,
                    square_meters:           null,
                    unblock_alarm_code:      null,
                    block_alarm_code:        null,
                    alarm_code_instructions: null,
                    geofence_radius:         200,
                    map_lat:                 59.3260664,
                    map_lng:                 17.8471233,
                };
            },
        },
    },

    data() {
        return {
            addressDataRules: {
                street:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
            marker: {
                map_lat: 59.3260664,
                map_lng: 17.8471233,
            },
        };
    },

    methods: {
        placeFound(data) {
            // @Filip
            this.addressData.post_code = data.postcode;
            this.addressData.city = data.city;
            this.addressData.street = data.street;
            this.addressData.map_lat = data.map_lat;
            this.addressData.map_lng = data.map_lng;
            this.marker.map_lat = data.map_lat;
            this.marker.map_lng = data.map_lng;

            this.$refs.addressForm.validate(() => {});
        },

        markerMapDragged({ mapLat, mapLng }) {
            this.marker.map_lat = mapLat;
            this.marker.map_lng = mapLng;
            this.addressData.map_lat = mapLat;
            this.addressData.map_lng = mapLng;
        },
    },
};
</script>
<style scoped>
    .el-select {
        width: 100%;
    }
</style>
