<template>
    <div class="pb-3 flex flex-col items-center w-full">
        <div class="w-10/12" style="max-width: 800px">
            <div class="card">
                <el-steps :active="activeStep" finish-status="success" align-center>
                    <el-step :title="$t('clients.client_information')" />
                    <el-step :title="$t('clients.address')" />
                    <el-step :title="$t('clients.billing_information')" />
                </el-steps>
            </div>
            <div>
                <StepOne v-show="activeStep === 0" ref="stepOne" @change="clientData = $event" />

                <StepTwo v-show="activeStep === 1" ref="stepTwo" :addressData="addressData" />

                <StepThree v-show="activeStep === 2" ref="stepThree" :billingData="billingData" :clientData="clientData" @onChange="onChangeStepThree" />

                <el-button type="primary" :disabled="activeStep <= 0" @click="previousStep">
                    {{ $t('clients.previous') }}
                </el-button>
                <el-button type="primary" :disabled="activeStep >= 2" @click="nextStep">
                    {{ $t('clients.next') }}
                </el-button>
                <el-button v-if="activeStep === 2" type="success" :loading="$wait.is('creating')" @click="nextStep">
                    {{ $t('clients.finish') }}
                </el-button>
            </div>
        </div>
    </div>
</template>
<script>
import StepOne from './components/ClientsCreateStepOne.vue';
import StepTwo from './components/ClientsCreateStepTwo.vue';
import StepThree from './components/ClientsCreateStepThree.vue';

export default {
    components: { StepOne, StepTwo, StepThree },

    data() {
        return {
            activeStep: 0,
            clientData: {
                type:       'private',
                manager_id: null,
            },
            addressData: {
                street:          null,
                post_code:       null,
                city:            null,
                geofence_radius: 200,
                map_lat:         59.3260664,
                map_lng:         17.8471233,
            },
            billingData: {
                enable_billing:    false,
                has_rut:           true,
                invoice_by_mail:   false,
                email:             null,
                name:              null,
                surname:           null,
                co:                null,
                street:            null,
                post_code:         null,
                city:              null,
                per_org_nummer:    null,
                autogiro_id:       null,
                invoice_type:      null,
                payment_method_id: null,
            },
        };
    },

    computed: {
        clientType() {
            return this.clientData.type;
        },
    },

    watch: {
        clientType() {
            if (this.clientType === 'company') {
                this.billingData.has_rut = false;
            }
        },
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('clients.add_new_client'));
        this.$store.commit('setActiveMenuItem', '1-2');
    },

    methods: {
        async createClientRequest() {
            this.$wait.start('creating');
            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/clients`, {
                    // Client data
                    client_type:             this.clientData.type,
                    client_custom_id:        this.clientData.custom_id,
                    client_name:             this.clientData.name,
                    client_surname:          this.clientData.surname,
                    client_login:            this.clientData.login,
                    client_email:            this.clientData.email,
                    client_telephone_number: this.clientData.telephone_number,
                    client_password:         this.clientData.password,
                    client_per_org_nummer:   this.clientData.per_org_nummer,
                    client_manager_id:       this.clientData.manager_id,

                    // Address data
                    address_co:                      this.addressData.co,
                    address_post_code:               this.addressData.post_code,
                    address_city:                    this.addressData.city,
                    address_street:                  this.addressData.street,
                    address_telephone:               this.addressData.telephone,
                    address_door_code:               this.addressData.door_code,
                    address_apartment_type:          this.addressData.apartment_type,
                    address_square_meters:           this.addressData.square_meters,
                    address_unblock_alarm_code:      this.addressData.unblock_alarm_code,
                    address_block_alarm_code:        this.addressData.block_alarm_code,
                    address_alarm_code_instructions: this.addressData.alarm_code_instructions,
                    address_geofence_radius:         this.addressData.geofence_radius,
                    address_map_lat:                 this.addressData.map_lat,
                    address_map_lng:                 this.addressData.map_lng,

                    // Billing data
                    billing_enabled:           this.billingData.enable_billing,
                    billing_has_rut:           this.billingData.has_rut,
                    billing_invoice_by_mail:   this.billingData.invoice_by_mail,
                    billing_email:             this.billingData.email,
                    billing_name:              this.billingData.name,
                    billing_surname:           this.billingData.surname,
                    billing_co:                this.billingData.co,
                    billing_street:            this.billingData.street,
                    billing_post_code:         this.billingData.post_code,
                    billing_city:              this.billingData.city,
                    billing_per_org_nummer:    this.billingData.per_org_nummer,
                    billing_autogiro_id:       this.billingData.autogiro_id,
                    billing_invoice_type:      this.billingData.invoice_type,
                    billing_payment_method_id: this.billingData.payment_method_id,
                });
                this.$notify.success({ title: 'Success' });
                this.$wait.end('creating');
                this.$router.push({ name: 'clients_all' });
            } catch (error) {
                this.$wait.end('creating');
            }
        },

        nextStep() {
            if (this.activeStep > 2) return;

            if (this.activeStep === 0) {
                this.$refs.stepOne.$refs.clientForm.validate(valid => {
                    if (valid) {
                        this.activeStep++;
                    }
                });
                return;
            }

            if (this.activeStep === 1) {
                this.$refs.stepTwo.$refs.addressForm.validate(valid => {
                    if (valid) {
                        this.activeStep++;
                    }
                });
                return;
            }

            if (this.activeStep === 2) {
                if (!this.billingData.enable_billing) {
                    this.createClientRequest();
                    return;
                }
                this.$refs.stepThree.$refs.billingForm.validate(valid => {
                    if (valid) {
                        this.createClientRequest();
                    }
                });
            }
        },

        previousStep() {
            if (this.activeStep <= 0) return;
            this.activeStep--;
        },

        onChangeStepThree(value) {
            this.billingData = value;
        },
    },
};
</script>
