<template>
    <div class="card">
        <el-form ref="billingForm" :model="billingDataCopy" :rules="billingDataRules" label-width="250px" :validate-on-rule-change="false">
            <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                <el-col :span="16" :offset="1">
                    <el-form-item :label="$t('clients.enable_billing_for_that_client')">
                        <el-switch v-model="billingDataCopy.enable_billing" class="pl-3" :disabled="paymentMethods.length == 0" @change="resetForm" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                <el-col :span="16" :offset="1">
                    <el-form-item :label="$t('clients.has_rut_rot')">
                        <el-switch
                            v-model="billingDataCopy.has_rut"
                            :active-value="1"
                            :inactive-value="0"
                            class="pl-3"
                            :disabled="clientData.type === 'company' || !billingDataCopy.enable_billing"
                            @change="updateValidationRules"
                        />
                    </el-form-item>
                    <el-form-item :label="$t('clients.send_invoice_by_mail')">
                        <el-switch
                            v-model="billingDataCopy.invoice_by_mail"
                            :active-value="1"
                            :inactive-value="0"
                            class="pl-3"
                            :disabled="!billingDataCopy.enable_billing"
                            @change="onChange"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                <el-col :span="16" :offset="1">
                    <el-form-item :label="$t('clients.payment_method')" prop="payment_method_id">
                        <el-select
                            v-model="billingDataCopy.payment_method_id"
                            :disabled="!billingDataCopy.enable_billing"
                            class="pl-3"
                            @change="onChange"
                        >
                            <el-option
                                v-for="method in paymentMethods"
                                :key="method.id"
                                :value="method.id"
                                :label="paymentMethodNameFormatter(method.type)"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Autogiro id" prop="autogiro_id">
                        <el-input
                            v-model="billingDataCopy.autogiro_id"
                            :disabled="billingDataCopy.invoice_type !== 'autogiro'"
                            class="pl-3"
                            @change="onChange"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15" class="border-b border-solid border-gray-300 pb-4 mb-5">
                <el-col :span="16" :offset="1">
                    <el-form-item :label="clientData.type === 'company' ? 'Orgnummer' : 'Personummer'" prop="per_org_nummer">
                        <el-input
                            v-model="billingDataCopy.per_org_nummer"
                            v-mask="clientData.type === 'company' ? '######-####' : '########-####'"
                            :disabled="!billingDataCopy.enable_billing"
                            class="pl-3"
                            @change="onChange"
                        />
                    </el-form-item>
                    <el-form-item :label="$t('clients.name')" prop="name">
                        <el-input v-model="billingDataCopy.name" :disabled="!billingDataCopy.enable_billing" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item :label="$t('clients.surname')" prop="surname">
                        <el-input
                            v-model="billingDataCopy.surname"
                            :disabled="clientData.type === 'company' || !billingDataCopy.enable_billing"
                            class="pl-3"
                            @change="onChange"
                        />
                    </el-form-item>
                    <el-form-item label="Email" prop="email">
                        <el-input v-model="billingDataCopy.email" :disabled="!billingDataCopy.enable_billing" class="pl-3" @change="onChange" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="16" :offset="1">
                    <el-form-item label="CO">
                        <el-input v-model="billingDataCopy.co" :disabled="!billingDataCopy.enable_billing" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item :label="$t('clients.street')" prop="street">
                        <el-input v-model="billingDataCopy.street" :disabled="!billingDataCopy.enable_billing" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item :label="$t('clients.post_code')" prop="post_code">
                        <el-input v-model="billingDataCopy.post_code" :disabled="!billingDataCopy.enable_billing" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item :label="$t('clients.city')" prop="city">
                        <el-input v-model="billingDataCopy.city" :disabled="!billingDataCopy.enable_billing" class="pl-3" @change="onChange" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
import Vue from 'vue';
import VueTheMask from 'vue-the-mask';

Vue.use(VueTheMask);
export default {
    props: {
        billingData: {
            type: Object,
            default() {
                return {
                    billingData: {},
                };
            },
        },
        clientData: {
            type: Object,
            default() {
                return {
                    clientData: {},
                };
            },
        },
    },

    data() {
        return {
            paymentMethods:   [],
            billingDataCopy:  { ...this.billingData },
            billingDataRules: {
                invoice_type: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                email:        [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { type: 'email', message: this.$t('common.wrong_email_format'), trigger: 'blur' },
                ],
                payment_method_id: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                name:              [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                surname:           [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                street:            [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code:         [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:              [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                per_org_nummer:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
        };
    },

    computed: {
        invoiceByMail() {
            return this.billingDataCopy.invoice_by_mail;
        },

        invoiceType() {
            return this.billingDataCopy.invoice_type;
        },

        perOrgNummer() {
            return this.billingDataCopy.per_org_nummer;
        },

        hasRut() {
            return this.billingDataCopy.has_rut;
        },

        paymentMethod() {
            return this.billingDataCopy.payment_method_id;
        },

        clientType() {
            return this.clientData.type;
        },
    },

    watch: {
        paymentMethod() {
            if (!this.billingDataCopy.enable_billing) return;
            const type = this.paymentMethods.filter(item => item.id === this.billingDataCopy.payment_method_id);
            this.billingDataCopy.invoice_type = type[0].type;
        },
        clientType() {
            this.updateValidationRules();
        },
        hasRut() {
            this.updateValidationRules();
        },
        perOrgNummer() {
            this.updateValidationRules();
        },
        invoiceByMail() {
            this.updateValidationRules();
        },
        invoiceType() {
            const autogiroRequired = this.billingDataCopy.invoice_type === 'autogiro';
            Vue.set(this.billingDataRules, 'autogiro_id',
                [
                    { required: autogiroRequired, message: this.$t('common.required'), trigger: 'blur' },
                ]);
        },
    },

    created() {
        this.getPaymentMethods();
    },

    methods: {
        async getPaymentMethods() {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/settings/payment_methods`);
            this.paymentMethods = data;
            if (data.length === 0) {
                this.billingDataCopy.enable_billing = false;
            }
        },

        updateValidationRules() {
            // this.$refs.billingForm.resetFields();
            const surnameRequired = this.clientData.type !== 'company';
            const perOrNummerRules = this.clientData.type === 'company' ? 11 : 13;
            const perOrNummerRequired = !!(this.clientData.type === 'company' || this.billingDataCopy.has_rut === 1);
            const invoiceByMailRules = this.billingDataCopy.invoice_by_mail !== 1;

            Vue.set(this.billingDataRules, 'per_org_nummer',
                [
                    { required: perOrNummerRequired, message: this.$t('common.required'), trigger: 'blur' },
                    {
                        min: perOrNummerRules, max: perOrNummerRules, message: this.$t('common.required'), trigger: 'blur',
                    },
                    {
                        min: perOrNummerRules, max: perOrNummerRules, message: this.$t('common.required'), trigger: 'blur',
                    },
                ]);
            Vue.set(this.billingDataRules, 'email',
                [
                    { required: invoiceByMailRules, message: this.$t('common.required'), trigger: 'blur' },
                    { type: 'email', message: this.$t('common.wrong_email_format'), trigger: 'blur' },
                ]);

            Vue.set(this.billingDataRules, 'surname',
                [
                    { required: surnameRequired, message: this.$t('common.required'), trigger: 'blur' },
                ]);
            this.onChange();
        },

        paymentMethodNameFormatter(type) {
            if (type === 'autogiro') {
                return 'Autogiro';
            }
            return this.$t('clients.bank_transfer');
        },

        resetForm() {
            this.$refs.billingForm.resetFields();
            this.onChange();
            // this.billingDataCopy.type = null;
            // this.billingDataCopy.email = null;
            // this.billingDataCopy.name = null;
            // this.billingDataCopy.surname = null;
            // this.billingDataCopy.co = null;
            // this.billingDataCopy.street = null;
            // this.billingDataCopy.post_code = null;
            // this.billingDataCopy.city = null;
            // this.billingDataCopy.per_org_nummer = null;
            // this.billingDataCopy.autogiro_id = null;
            // this.billingDataCopy.invoice_type = null;
            // this.billingDataCopy.payment_method_id = null;
        },

        onChange() {
            this.$emit('onChange', this.billingDataCopy);
        },
    },
};
</script>
<style scoped>
    .el-select {
        width: 100%;
    }
</style>
<style>
.el-form-item__error {
    padding-left: 12px;
}
</style>
